import React, {
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Demo from "./containers/Demo";
import {
  UserCoin,
  Address,
  UserToken,
  Offer,
  OfferTx,
} from "./data/models/types";
import Header from "./components/Header";
import {newCoinContract, oldCoinContract} from "./data/abi/erc20";
import {nftContract} from "./data/abi/nft";
import {traderContract} from "./data/abi/trader";
import {useReadContract, useWalletClient} from "wagmi";
import {Web3Provider} from "./data/Web3Provider";
import {UserContext, ContextData} from "./data/UserContext";
import {DateTime} from "luxon";
import "react-toastify/dist/ReactToastify.css";
import Login from "./login/Login";
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import { Loading } from "./components/loading/Loading";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

interface Props {
  children?: ReactNode;
}

const UserProvider = ({children, ...props}: Props) => {
  const [loading, setLoading] = useState(true);
  const [myFtkx, setMyFtkx] = useState(null as UserCoin);
  const [myUsdt, setMyUsdt] = useState(null as UserCoin);
  const [myFtkxAllowance, setMyFtkxAllowance] = useState(BigInt(0) as BigInt);
  const [myUsdtAllowance, setMyUsdtAllowance] = useState(BigInt(0) as BigInt);
  const [myTokenDate, setMyTokenDate] = useState(BigInt(0) as BigInt | null);
  const [myToken, setMyToken] = useState(null as UserToken);
  const [myAddress, setMyAddress] = useState(null as Address);
  const [offers, setOffers] = useState([] as Array<Offer>);
  const [offers2, setOffers2] = useState([] as Array<Offer>);
  const [offers3, setOffers3] = useState([] as Array<Offer>);
  const [offersExtra, setOffersExtra] = useState([] as Array<Array<Offer>>);
  const [myOffers, setMyOffers] = useState([] as Array<Offer>);
  const [myDateTime, setMyDateTIme] = useState(DateTime.now());
  const [myOfferTransactions, setMyOfferTransactions] = useState(
    [] as Array<OfferTx>
  );
  const [extraPages, setExtraPages] = useState(0);
  const [myError, setMyError] = useState(null as string | null);
  const [mySuccess, setMySuccess] = useState(null as string | null);
  const contextValues = {} as ContextData;
  contextValues.ctxMyOfferTransactions = myOfferTransactions;
  contextValues.ctxFtkx = myFtkx;
  contextValues.ctxUsdt = myUsdt;
  contextValues.ctxFtkxAllowance = myFtkxAllowance;
  contextValues.ctxUsdtAllowance = myUsdtAllowance;
  contextValues.ctxToken = myToken;
  contextValues.ctxTokenDate = myTokenDate;
  contextValues.ctxAddress = myAddress;
  contextValues.ctxOffers = offers;
  contextValues.ctxOffers2 = offers2;
  contextValues.ctxOffers3 = offers3;
  contextValues.ctxOffersExtra = offersExtra;
  contextValues.ctxMyOffers = myOffers;
  contextValues.ctxLastUpdate = myDateTime;
  contextValues.ctxError = myError;
  contextValues.ctxSuccess = mySuccess;
  contextValues.ctxExtraPages = extraPages;
  contextValues.ctxSetFtkx = setMyFtkx;
  contextValues.ctxSetUsdt = setMyUsdt;
  contextValues.ctxSetFtkxAllowance = setMyFtkxAllowance;
  contextValues.ctxSetUsdtAllowance = setMyUsdtAllowance;
  contextValues.ctxSetAddress = setMyAddress;
  contextValues.ctxSetToken = setMyToken;
  contextValues.ctxSetTokenDate = setMyTokenDate;
  contextValues.ctxSetOffers = setOffers;
  contextValues.ctxSetOffers2 = setOffers2;
  contextValues.ctxSetOffers3 = setOffers3;
  contextValues.ctxSetOffersExtra = setOffersExtra;
  contextValues.ctxSetMyOffers = setMyOffers;
  contextValues.ctxSetMyLastUpdate = setMyDateTIme;
  contextValues.ctxSetMyOfferTransactions = setMyOfferTransactions;
  contextValues.ctxSetError = setMyError;
  contextValues.ctxSetSuccess = setMySuccess;
  contextValues.ctxSetExtraPages = setExtraPages;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <UserContext.Provider value={contextValues}>
      {loading ? <Loading /> : children}
    </UserContext.Provider>
  );
};

const Body = () => {
  const {
    ctxAddress,
    ctxFtkx,
    ctxUsdt,
    ctxToken,
    ctxTokenDate,
    ctxOffers,
    ctxOffers2,
    ctxOffers3,
    ctxOffersExtra,
    ctxMyOffers,
    ctxLastUpdate,
    ctxFtkxAllowance,
    ctxUsdtAllowance,
    ctxExtraPages,
    ctxSetAddress,
    ctxSetFtkx,
    ctxSetUsdt,
    ctxSetToken,
    ctxSetTokenDate,
    ctxSetOffers,
    ctxSetOffers2,
    ctxSetOffers3,
    ctxSetOffersExtra,
    ctxSetMyOffers,
    ctxSetMyLastUpdate,
    ctxSetFtkxAllowance,
    ctxSetUsdtAllowance,
    ctxSetError,
    ctxError,
  } = useContext(UserContext);
  const navigate = useNavigate();

  const {data: walletClient} = useWalletClient();
  if (
    walletClient?.account.address &&
    ctxAddress !== walletClient?.account.address
  ) {
    ctxSetAddress(walletClient.account.address);
  }

  useEffect(() => {
    offersRefetch2()
  }, [ctxOffers]);
  useEffect(() => {
    offersRefetch3()
  }, [ctxOffers2]);
  const {data: oldCoinData, refetch: oldCoinRefetch} = useReadContract({
    ...oldCoinContract,
    functionName: "balanceOf",
    args: [ctxAddress || "0x0000000000000000000000000000000000000000"],
    query: {},
  });
  if (
    ctxAddress &&
    oldCoinData !== null &&
    oldCoinData !== undefined &&
    tokenTransformer(oldCoinData.toString(), 18, 6) !== ctxFtkx
  ) {
    ctxSetFtkx(tokenTransformer(oldCoinData.toString(), 18, 6));
  }

  const {data: oldCoinAllowance, refetch: oldCoinAllowanceRefetch} =
    useReadContract({
      ...oldCoinContract,
      functionName: "allowance",
      args: [
        ctxAddress || "0x0000000000000000000000000000000000000000",
        traderContract.address,
      ],
      query: {},
    });
  if (
    ctxAddress &&
    oldCoinAllowance !== null &&
    oldCoinAllowance !== undefined &&
    oldCoinAllowance !== ctxFtkxAllowance
  ) {
    ctxSetFtkxAllowance(oldCoinAllowance as BigInt);
  }

  const {data: newCoinData, refetch: newCoinRefetch} = useReadContract({
    ...newCoinContract,
    functionName: "balanceOf",
    args: [ctxAddress || "0x0000000000000000000000000000000000000000"],
    query: {},
  });
  if (
    ctxAddress &&
    newCoinData !== null &&
    newCoinData !== undefined &&
    tokenTransformer(newCoinData.toString(), 6, 2) !== ctxUsdt
  ) {
    ctxSetUsdt(tokenTransformer(newCoinData.toString(), 6, 2));
  }

  const {data: newCoinAllowance, refetch: newCoinAllowanceRefetch} =
    useReadContract({
      ...newCoinContract,
      functionName: "allowance",
      args: [
        ctxAddress || "0x0000000000000000000000000000000000000000",
        traderContract.address,
      ],
      query: {},
    });
  if (
    ctxAddress &&
    newCoinAllowance !== null &&
    newCoinAllowance !== undefined &&
    newCoinAllowance !== ctxUsdtAllowance
  ) {
    ctxSetUsdtAllowance(newCoinAllowance as BigInt);
  }
  const responseDataNft = useReadContract({
    ...nftContract,
    functionName: "getNFTInfo",
    args: [ctxAddress || "0x0000000000000000000000000000000000000000"],
    query: {},
  });

  if (
    ctxAddress &&
    responseDataNft.data !== null &&
    responseDataNft.data !== undefined &&
    responseDataNft.data[1] !== ctxTokenDate
  ) {
    ctxSetToken(responseDataNft.data[0]);
    ctxSetTokenDate(responseDataNft.data[1]);
  }


  const {data: myOffersData, refetch: myOffersRefetch} = useReadContract({
    ...traderContract,
    functionName: "getOffers",
    args: [walletClient?.account.address],
    query: {},
  });
  const mOffersData = myOffersData as any[] | undefined;

  if (
    ctxAddress &&
    mOffersData !== null &&
    mOffersData !== undefined &&
    mOffersData.filter((d: any) => d[8]).length !==
      ctxMyOffers.filter((c) => c.isActive).length
  ) {
    ctxSetMyOffers(
      mOffersData.map((d) => {
        return {
          seller: d[6],
          offerId: Number(d[7]),
          ftkxCoin: tokenTransformer(d[4].toString(), 18, 6),
          usdtCoin: tokenTransformer(d[5].toString(), 6, 2),
          hasBeenSold: d[9],
          isActive: d[8],
        } as Offer;
      })
    );
  }
  const {data: oData, refetch: offersRefetch} = useReadContract({
    ...traderContract,
    functionName: "getOfferLinkedList",
    args: ["0x0000000000000000000000000000000000000000", 0],
    query: {},
  });
  const offersData = oData as any[] | undefined;

  if (
    ctxAddress &&
    offersData !== null &&
    offersData !== undefined &&
    offersData.filter((d: any) => d[8]).length !== ctxOffers.length
  ) {
    ctxSetOffers(
      offersData
        .filter((d: any) => d[8])
        .map((d) => {
          return {
            seller: d[6],
            offerId: Number(d[7]),
            ftkxCoin: tokenTransformer(d[4].toString(), 18, 6),
            usdtCoin: tokenTransformer(d[5].toString(), 6, 2),
            hasBeenSold: d[9],
            isActive: d[8],
          } as Offer;
        })
    );
  }
  const {data: oData2, refetch: offersRefetch2} = useReadContract({
    ...traderContract,
    functionName: "getOfferLinkedList",
    args: [(offersData?.[19]?.[2] ? offersData[19][2] :"0x0000000000000000000000000000000000000000"), 
    offersData?.[19]?.[3] ? Number(offersData[19][3]): 0],
    query: {},
  });
  const offersData2 = oData2 as any[] | undefined;
  if (
    ctxAddress &&
    offersData  !== null &&
    offersData2  !== null &&
    offersData2 !== undefined &&
    offersData2.filter((d: any) => d[8]).length !== ctxOffers2.length &&
    offersData && offersData?.[19]?.[8] 
  ) {
    ctxSetOffers2(
      offersData2
        .filter((d: any) => d[8])
        .map((d) => {
          return {
            seller: d[6],
            offerId: Number(d[7]),
            ftkxCoin: tokenTransformer(d[4].toString(), 18, 6),
            usdtCoin: tokenTransformer(d[5].toString(), 6, 2),
            hasBeenSold: d[9],
            isActive: d[8],
          } as Offer;
        })
    );
  }

  const {data: oData3, refetch: offersRefetch3} = useReadContract({
    ...traderContract,
    functionName: "getOfferLinkedList",
    args: [(offersData2?.[19]?.[2] ? offersData2[19][2] :"0x0000000000000000000000000000000000000000"), 
        offersData2?.[19]?.[3] ? Number(offersData2[19][3]): 0],
    query: {},
  });
  const offersData3 = oData3 as any[] | undefined;

  if (
    ctxAddress &&
    offersData3 !== null &&
    offersData3 !== undefined &&
    offersData3.filter((d: any) => d[8]).length !== ctxOffers3.length &&
    offersData2 && offersData2?.[19]?.[8] 
  ) {
    ctxSetOffers3(
      offersData3
        .filter((d: any) => d[8])
        .map((d) => {
          return {
            seller: d[6],
            offerId: Number(d[7]),
            ftkxCoin: tokenTransformer(d[4].toString(), 18, 6),
            usdtCoin: tokenTransformer(d[5].toString(), 6, 2),
            hasBeenSold: d[9],
            isActive: d[8],
          } as Offer;
        })
    );
  }

  var lastAddress = "0x0000000000000000000000000000000000000000"
  var lastId = 0
  if((offersData3?.length ?? 0) >= 20) {
    if(ctxOffersExtra.length >= 1) {
      var lastElement = ctxOffersExtra[ctxOffersExtra.length - 1];
      if(lastElement.filter(a => a.isActive).length >= 19){
        lastAddress = lastElement[18].seller;
        lastId = lastElement[18].offerId;
      }
    } else {
      lastAddress = (offersData3 && offersData3[19][2] as string) ?? lastAddress;
      lastId = (offersData3 && offersData3[19][3] as number) ?? lastId;
    }
  }
  const {data: oDataExtra, refetch: offersDataExtraRefetch} = useReadContract({
    ...traderContract,
    functionName: "getOfferLinkedList",
    args: [lastAddress, lastId],
    query: {},
  });
  const offersDataExtra = oDataExtra as any[] | undefined;

  if (
    ctxAddress &&
    offersDataExtra !== null &&
    offersDataExtra !== undefined &&
    ctxOffersExtra.length < ctxExtraPages && 
    lastAddress !== "0x0000000000000000000000000000000000000000"
  ) {
    ctxSetOffersExtra(
      ctxOffersExtra.concat(
        [
          offersDataExtra
          .filter((d: any, i) => (i > 0 && d[8]))
          .map((d) => {
            return {
              seller: d[6],
              offerId: Number(d[7]),
              ftkxCoin: tokenTransformer(d[4].toString(), 18, 6),
              usdtCoin: tokenTransformer(d[5].toString(), 6, 2),
              hasBeenSold: d[9],
              isActive: d[8],
            } as Offer;
          })
        ]
      )
    );
  }
  useEffect(() => {
    if(ctxExtraPages > ctxOffersExtra.length){
      offersDataExtraRefetch();
    }
  },[ctxExtraPages])
  const refetchAll = () => {
    oldCoinRefetch();
    oldCoinAllowanceRefetch();
    newCoinAllowanceRefetch();
    newCoinRefetch();
    offersRefetch();
    offersRefetch2();
    offersRefetch3();
    ctxSetOffersExtra([]);
    myOffersRefetch();
    ctxSetMyLastUpdate(DateTime.now());
  };
  const refetchLoop = () => {
    if (ctxLastUpdate.diffNow().toMillis() < -30000) {
      oldCoinRefetch();
      oldCoinAllowanceRefetch();
      newCoinAllowanceRefetch();
      newCoinRefetch();
      offersRefetch();
      offersRefetch2();
      offersRefetch3();
      myOffersRefetch();
      ctxSetMyLastUpdate(DateTime.now());
    }
  };
  useEffect(() => {
    refetchLoop();
  }, []);

  return (
    <div className="flexColumn">
      <Header />
      <Demo refetchAll={refetchAll} />
    </div>
  );
};

root.render(
  <BrowserRouter>
    <React.StrictMode>
      <UserProvider>
        <Web3Provider>
          <Routes>
            <Route path="/app" element={<Body />} />
            <Route path="/" element={<Login />} />
          </Routes>
        </Web3Provider>
      </UserProvider>
    </React.StrictMode>
  </BrowserRouter>
);


const tokenTransformer = (
  raw: string,
  decimals: number,
  decimalsToShow: number
) => {
  raw = raw
    .split("")
    .filter((d) => "0123456789".includes(d))
    .join("")
    .normalize();
  let rawIntegers = BigInt(raw).toString();

  if (rawIntegers.length <= decimals) {
    return (
      "0." + rawIntegers.padStart(decimals, "0").substring(0, decimalsToShow)
    );
  }
  let rawdecimals = rawIntegers.substring(rawIntegers.length - decimals);
  rawIntegers = rawIntegers.substring(0, rawIntegers.length - decimals);

  return rawIntegers + "." + rawdecimals.substring(0, decimalsToShow);
};
