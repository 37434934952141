import React, {useContext} from "react";
import { WagmiProvider, createConfig, http, CreateConfigParameters, Config } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConnectKitProvider, getDefaultConfig } from "connectkit";
import { config } from "./wagmiConfig";
import MegaMakerAvatar from "./MegaMakerAvatar";
import {UserContext} from "./UserContext"


const queryClient = new QueryClient();
type Props = {
  children: string | JSX.Element | JSX.Element[];
}


export const Web3Provider = (props: Props ) => {
  const { ctxToken } = useContext(UserContext);
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider theme="midnight" options={
          {
            customAvatar:MegaMakerAvatar(Number(ctxToken) || 0),
          }
        } >{props.children}</ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
};
