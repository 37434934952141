import { Types } from "connectkit";
import commonToken from "./tokens/1.jpeg"
import bronzeToken from "./tokens/2.jpeg"
import silverToken from "./tokens/3.jpeg"
import goldToken from "./tokens/4.jpeg"
import emeraldToken from "./tokens/5.jpeg"
import titaniumToken from "./tokens/6.jpeg"
import titaniumToken2 from "./tokens/7.jpeg"
import ftkxImage from "./tokens/ftkx.png"

const MegaMakerAvatar = (token:number) => ({ address, ensImage, ensName, size, radius }: Types.CustomAvatarProps) => {
  let tokenImage;
  switch(token) {
    case 1:
      tokenImage = commonToken;
      break;
    case 2:
      tokenImage = bronzeToken;
      break;
    case 3:
      tokenImage = silverToken;
      break;
    case 4:
      tokenImage = goldToken;
      break;
    case 5:
      tokenImage = emeraldToken;
      break;
    case 6:
      tokenImage = titaniumToken;
      break;
    case 7:
      tokenImage = titaniumToken2;
      break;
    default:
      tokenImage = ftkxImage;
  }
  return (
    <div
      style={{
        overflow: "hidden",
        borderRadius: radius,
        height: size,
        width: size,
        background: tokenImage,
      }}
    >
      {tokenImage && <img src={tokenImage} alt={address} width="100%" height="100%" />}
    </div>
  );
};

export default MegaMakerAvatar;
