
import { useEffect} from 'react';
import  "./Loading.css";

import { ToastContainer, toast } from 'react-toastify';



function LoadingModal() {
  useEffect(() => {
    toast.info('Your request is being processed!');
  }, []);

  return (
     <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      />
  );
}

export default LoadingModal;