import {createContext} from 'react'
import {UserCoin, UserToken, Address, Offer, OfferTx} from './models/types'
import { DateTime } from 'luxon'

export const UserContext = createContext({
  ctxFtkx: null as UserCoin,
  ctxUsdt: null as UserCoin,
  ctxFtkxAllowance: BigInt(0) as BigInt,
  ctxUsdtAllowance: BigInt(0) as BigInt,
  ctxTokenDate: BigInt(0) as BigInt | null,
  ctxToken: null as UserToken,
  ctxAddress: null as Address,
  ctxOffers: [] as Array<Offer>,
  ctxOffers2: [] as Array<Offer>,
  ctxOffers3: [] as Array<Offer>,
  ctxOffersExtra: [] as Array<Array<Offer>>,
  ctxMyOffers: [] as Array<Offer>,
  ctxMyOfferTransactions: [] as Array<OfferTx>,
  ctxLastUpdate: DateTime.now(),
  ctxError: null as string | null,
  ctxSuccess: null as string | null,
  ctxExtraPages: 0 as number,
  ctxSetFtkx: ()=>null,
  ctxSetUsdt: ()=>null,
  ctxSetFtkxAllowance: ()=>null,
  ctxSetUsdtAllowance: ()=>null,
  ctxSetTokenDate: () => null,
  ctxSetToken: ()=>null,
  ctxSetAddress: ()=>null,
  ctxSetOffers: ()=>null,
  ctxSetOffers2: ()=>null,
  ctxSetOffers3: ()=>null,
  ctxSetOffersExtra: () => null,
  ctxSetMyOffers: ()=>null,
  ctxSetMyOfferTransactions: ()=>null,
  ctxSetMyLastUpdate: () =>null,
  ctxSetError: () => null,
  ctxSetSuccess:() => null,
  ctxSetExtraPages:() => null,
} as ContextData)

export type ContextData = {
  ctxFtkx: UserCoin,
  ctxUsdt: UserCoin,
  ctxFtkxAllowance: BigInt,
  ctxUsdtAllowance: BigInt,
  ctxTokenDate: BigInt | null,
  ctxToken: UserToken,
  ctxAddress: Address,
  ctxOffers: Array<Offer>,
  ctxOffers2: Array<Offer>,
  ctxOffers3: Array<Offer>,
  ctxOffersExtra: Array<Array<Offer>>,
  ctxMyOffers: Array<Offer>,
  ctxMyOfferTransactions: Array<OfferTx>,
  ctxLastUpdate: DateTime,
  ctxError: null | string,
  ctxSuccess: null | string,
  ctxExtraPages: number,
  ctxSetFtkx: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetUsdt: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetFtkxAllowance: React.Dispatch<React.SetStateAction<BigInt>>,
  ctxSetUsdtAllowance: React.Dispatch<React.SetStateAction<BigInt>>,
  ctxSetToken: React.Dispatch<React.SetStateAction<bigint | null>>,
  ctxSetTokenDate: React.Dispatch<React.SetStateAction<BigInt | null>>,
  ctxSetAddress: React.Dispatch<React.SetStateAction<`0x${string}` | null>>,
  ctxSetOffers: React.Dispatch<React.SetStateAction<Offer[]>>,
  ctxSetOffers2: React.Dispatch<React.SetStateAction<Offer[]>>,
  ctxSetOffers3: React.Dispatch<React.SetStateAction<Offer[]>>,
  ctxSetOffersExtra: React.Dispatch<React.SetStateAction<Offer[][]>>,
  ctxSetMyOffers: React.Dispatch<React.SetStateAction<Offer[]>>,
  ctxSetMyOfferTransactions: React.Dispatch<React.SetStateAction<OfferTx[]>>,
  ctxSetMyLastUpdate: React.Dispatch<React.SetStateAction<DateTime>>,
  ctxSetError: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetSuccess: React.Dispatch<React.SetStateAction<string | null>>,
  ctxSetExtraPages: React.Dispatch<React.SetStateAction<number>>,
}