export type UserCoin = string | null;
export type UserToken = bigint | null;

export type Offer = {
  seller: any
  offerId: any
  ftkxCoin: string
  usdtCoin: string
  isActive: boolean
  hasBeenSold: boolean
}

export enum TransactionType {
  Error,
  ApproveOffer,
  ApproveBuy,
  ApproveUpdate,
  Buy,
  Offer,
  Sell,
  Update,
  Delete
}

export enum TxStatus {
  Waiting,
  Confirmed,
  Error
}

export type OfferTx = {
  confirmedAt: any
  type: number
  seller: Address
  offerId: number
  ftkxCoin: string
  usdtCoin: string
  status: number
}

export type Address = null | `0x${string}`