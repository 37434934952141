import React, {useContext, useEffect, useState} from "react";
import "./Header.css";
import {ConnectKitButton} from "connectkit";
import ftkImg from "../containers/mkfx.png";
import imgT  from "../containers/usdt.png";
import logoF  from "../containers/logoDentroNew.png";

import {UserContext} from "../data/UserContext";
import Spinner from "react-bootstrap/Spinner";

import logo from "./../containers/LogoWhite.png";

const Header = () => {
  const {ctxUsdt, ctxFtkx} = useContext(UserContext);
  const [btnMobile, setBtnMobile] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 805) {
        setBtnMobile(true);
      } else {
        setBtnMobile(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header
      className="App-header"
      style={{marginBottom: isOpen ? "125px" : ""}}
    >
      <div className="flexRow fullWidth">
        <div className="logo">
          {btnMobile ? (
            <img src={logo} alt="logo" className="logoF" style={{height: '40px', width: '130px'}}/>
          ) : (
            <img src={logo} alt="logo" />
          )}
        </div>

        <div
          className="connect-button"
          style={{display: `${btnMobile === true ? "none" : "block"}`}}
        >
          <span className="usdtBadge">
            <img src={imgT} alt="img" style={{maxHeight: '70%'}} />
            {<p>{ctxUsdt ? ctxUsdt : <Spinner size="sm" />}</p>}
          </span>
        </div>
        <div
          className="connect-button"
          style={{display: `${btnMobile === true ? "none" : "block"}`}}
        >
          <span className="ftkxBadge">
            <img src={ftkImg} alt="img" />
            {ctxFtkx ? <p>{ctxFtkx} </p> : <Spinner size="sm" />}
          </span>
        </div>
        <div
          className="connect-button"
        >
          <ConnectKitButton theme="midnight" customTheme={"connect-button"} />
        </div>


        {/* {btnMobile && <Hamburger toggled={isOpen} toggle={setOpen} color="#fff"/>} */}
        {/* {isOpen && (
          <div className="connectRepons">
            <div className="connect-button">
              <ConnectKitButton
                theme="midnight"
                customTheme={"connect-button"}
              />
            </div>
            <div className="connect-button">
              <span className="usdtBadge">
                <Wallet />
                <p>$</p>
                {ctxUsdt ? <p> {ctxUsdt} </p> : <Spinner size="sm" />}
              </span>
            </div>
            <div className="connect-button">
              <span className="ftkxBadge">
                <img src={ftkImg} alt="img" />
                {ctxFtkx ? <p> {ctxFtkx} </p> : <Spinner size="sm" />}
              </span>
            </div>
          </div>
        )} */}
      </div>
    </header>
  );
};

export default Header;
