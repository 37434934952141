import { createConfig, http, CreateConfigParameters, Config } from "wagmi";
import { getDefaultConfig } from "connectkit";
import {testNet} from './amoy'
import { polygon } from "wagmi/chains"
import { walletConnect } from 'wagmi/connectors'
import { metaMask } from 'wagmi/connectors'
import { injected } from 'wagmi/connectors'

const configExecutor: (parameters: CreateConfigParameters) => Config  = createConfig;

export const config = configExecutor(
  getDefaultConfig({
    // chains: [testNet],
    // transports: {
    //   [testNet.id]: http()
    // },
    chains: [polygon],
    transports: {
      [polygon.id]: http(`https://polygon-mainnet.g.alchemy.com/v2/QETWM1FfuLaQ2yEVKhSKAB9AkfsBq4-4`),
    },
    connectors: [
      injected(),
      walletConnect({
        projectId: 'f1011529a650403b9293876045da1a41',
      }),
    ],

    walletConnectProjectId: "f1011529a650403b9293876045da1a41",

    appName: "FTKXMarket",

    appDescription: "",
    appUrl: "https://megaplace.org", // your app's url
    appIcon: "https://family.co/logo.png",
  }),
);