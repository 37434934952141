export const traderABI = [
  {
    type: 'function',
    name: 'changeRatio',
    inputs: [{ name: 'dividendNewValue', type: 'uint256' },
             { name: 'divisorNewValue', type: 'uint256' }],
    outputs: [{ type: 'bool' }],
  },
  {
    type: 'function',
    name: 'getTaxSum',
    stateMutability: 'view',
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'withdrawTax',
    inputs: [{ name: 'amount', type: 'uint256' }],
    outputs: [{ type: 'bool' }],
  },
  {
    type: 'function',
    name: 'getOfferCount',
    stateMutability: 'view',
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'createNewOffer',
    inputs: [{ name: 'oldCoins', type: 'uint256' },
             { name: 'newCoins', type: 'uint256' }],
    outputs: [{ type: 'bool' }],
  },
  {
    type: 'function',
    name: 'cancelOffer',
    inputs: [{ name: 'offerId', type: 'uint8' }],
    outputs: [{ type: 'bool' }],
  },
  {
    type: 'function',
    name: 'updateOffer',
    inputs: [{ name: 'offerId', type: 'uint8' },
             { name: 'oldCoins', type: 'uint256' },
             { name: 'newCoins', type: 'uint256' }],
    outputs: [{ type: 'bool' }],
  },
  {
    type: 'function',
    name: 'acceptOffer',
    inputs: [{ name: 'seller', type: 'address' },
             { name: 'offerId', type: 'uint8' }],
    outputs: [{ type: 'bool' }],
  },
  {
    type: 'function',
    name: 'withdrawTokens',
    inputs: [{ name: 'tokenType', type: 'uint8' },
             { name: 'amount', type: 'uint256' }],
    outputs: [{ type: 'bool' }],
  },
  {
    type: 'function',
    name: 'getOffers',
    stateMutability: 'view',
    inputs: [{ name: 'seller', type: 'address' }],
    outputs: [{ type: 'tuple[4]', components:[
      {type: 'address'},
      {type: 'uint8'},
      {type: 'address'},
      {type: 'uint8'},
      {type: 'uint256'},
      {type: 'uint256'},
      {type: 'address'},
      {type: 'uint8'},
      {type: 'bool'},
      {type: 'bool'},
     ]
    }],
  },
  {
    type: 'function',
    name: 'getOfferLinkedList',
    stateMutability: 'view',
    inputs: [{ name: 'offerer', type: 'address' },
             { name: 'offerId', type: 'uint8' }],
    outputs: [{ type: 'tuple[20]', components:[
      {type: 'address'},
      {type: 'uint8'},
      {type: 'address'},
      {type: 'uint8'},
      {type: 'uint256'},
      {type: 'uint256'},
      {type: 'address'},
      {type: 'uint8'},
      {type: 'bool'},
      {type: 'bool'},
     ]
  }],
  },



] as const

export const traderContract = {
  address: '0x39E37a15395183B093a8ac5090589D7e082E7aB3',
  //TestNet:
  // address: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
  abi: traderABI
} as const
