
function FormatNumber(numero: number, min = 4): string {
  return numero.toLocaleString('en-US', {
    minimumFractionDigits: 2 ,
    maximumFractionDigits: min
  });
}

function FormatUsdt(rawUsdt: string) : string {
  const newUsdtStr = rawUsdt.split('.')[0] + rawUsdt.split('.')[1].substring(0,2);
  return newUsdtStr
}

function getMinifiedAddress(address: string): string {
  if (address) {
    return (
      address.slice(0, 5) +
      "...." +
      address.slice(address.length - 4, address.length)
    );
  } else {
    return "none";
  }
}
export { FormatNumber, FormatUsdt, getMinifiedAddress }

