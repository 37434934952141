
import { useEffect} from 'react';

import { ToastContainer, toast } from 'react-toastify';



type DemoProps = {
  successMessage:  string;
}
function SuccessModal({successMessage}: DemoProps) {
  useEffect(() => {
    toast.success(successMessage);
  }, [successMessage]);

  return (
     <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      />
  );
}

export default SuccessModal;