export const balanceOfABI = [
  {
    type: 'function',
    name: 'balanceOf',
    stateMutability: 'view',
    inputs: [{ name: 'account', type: 'address' }],
    outputs: [{ type: 'uint256' }],
  },
  {
    type: 'function',
    name: 'approve',
    inputs: [{ name: 'account', type: 'address' },
             { name: 'amount', type: 'uint256' }],
    outputs: [{ type: 'bool' }],
  },
  {
    type: 'function',
    name: 'allowance',
    inputs: [{ name: 'owner', type: 'address' },
             { name: 'spender', type: 'address' }],
    outputs: [{ type: 'uint256' }],
  },
] as const

export const oldCoinContract = {
  address: '0xF0b9CBA3873516c69ea52FBBE11259812495704D',
  //TestNet:
  // address: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
  abi: balanceOfABI
} as const

export const newCoinContract = {
  address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
  //TestNet:
  // address: '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
  abi: balanceOfABI
} as const