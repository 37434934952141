/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useContext, useEffect, useState} from "react";
import styles from "./Login.module.css";
import bg from './../containers/bgNew.png';

import login from "./../containers/login_FTKX.png";
import mega from "./../containers/MegaPlace.png";
import {ConnectKitButton} from "connectkit";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../data/UserContext";
import { useWalletClient } from "wagmi";

const Login = () => {
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const {ctxAddress, ctxSetAddress} = useContext(UserContext);
  const {data: walletClient} = useWalletClient();

  if (
    walletClient?.account.address &&
    ctxAddress !== walletClient?.account.address
  ) {
    ctxSetAddress(walletClient.account.address);
  }
  useEffect(() => {
    ctxSetAddress(address ? `0x${walletClient?.account.address}` : null);
    if (isConnected &&  ctxAddress != null) {
      navigate("/app");
    }
  }, [isConnected, navigate]);

  useEffect(() => {
    if (ctxAddress != null) {
      navigate("/app");
    }
  }, [ctxAddress])

  const handleClick = ({ show, isConnected, address }: { show: () => void; isConnected: boolean; address: string | undefined }) => {
    ctxSetAddress(address ? `0x${walletClient?.account.address}` : null);
    if(show) {
      show()
    }
    if (isConnected) {
      navigate("/app");
    }
  };


  return (
    <div className={styles.divContent}
    style={
      {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${bg})`,
        backgroundColor: "#000",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "90vh",
        zIndex: 9999,
        color: '#fff'
      }
    }>
      <div style={{display:'flex', justifyContent:'center'}}>
        <img style={{width:'130%'}} src={login} alt="login" />
      </div>
      <div className={styles.infoLogin}>
        <h1>Join to trade FTKX onChain.</h1>
        <p>Use our DEX tool to buy and sell FTKX with security and blockchain traceability.</p>

        <ConnectKitButton.Custom>
          {({ show, isConnected, address }) => (
            <button onClick={() => handleClick({ show: show || (() => {}), isConnected, address })} className={styles.btnLogin}>
              {isConnected?"Enter":"Connect Wallet"}
            </button>
          )}
        </ConnectKitButton.Custom>
        <div className="flexRow"> </div>
        <img src={mega} style={{width:'50%'}} alt="logo" />
      </div>
    </div>
  );
};

export default Login;
