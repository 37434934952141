"use client";
import React, {
  useState,
  useContext,
} from "react";
import "./Demo.css";
import {DateTime} from "luxon";
import LoadingModal from "../components/modals/Loading";
import ErrorModal from "../components/modals/Error";
import {
  Offer,
  TxStatus,
  TransactionType,
  Address,
} from "../data/models/types";
import {newCoinContract, oldCoinContract} from "../data/abi/erc20";
import {traderContract} from "../data/abi/trader";
import {
  useWriteContract,
  useWaitForTransactionReceipt,
} from "wagmi";
import {config} from "../data/wagmiConfig";
import {UserContext} from "../data/UserContext";
import {ToastContainer, toast} from "react-toastify";
import Spinner from "react-bootstrap/Spinner";

import {FormatNumber} from "./../utils/data";
import SuccessModal from "../components/modals/Success";

const imgX = require("./mkfx.png");
const imgT = require("./usdt.png");

const uint256MaxValue = BigInt("115792089237316195423570985008687907853269984665640564039457584007913129639935");

type Props = {
  refetchAll: Function;
};

const tokenToUint256 = (raw: string, decimals: number) => {
  if (raw === "" || raw === undefined) {
    return BigInt(0);
  }
  let splittedInt = ""
  let splittedDecimals = ""
  if (raw.split(".").length <= 1) {
    return BigInt(raw + "000000000000000000")
  }
  splittedInt = raw.split(".")[0];
  splittedDecimals = raw.split(".")[1];
  splittedInt = splittedInt
    .split("")
    .filter((d) => "0123456789".includes(d))
    .join("")
    .normalize();

  if (splittedDecimals.length <= decimals) {
    splittedDecimals = splittedDecimals.padEnd(decimals, "0");
  }
  return BigInt(splittedInt + splittedDecimals);
};
const OrderBook = ({refetchAll}: Props) => {
  toast.bind(<ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    limit={1}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="colored"
  />)
  const {
    ctxMyOfferTransactions,
    ctxOffers,
    ctxOffers2,
    ctxOffers3,
    ctxOffersExtra,
    ctxMyOffers,
    ctxLastUpdate,
    ctxTokenDate,
    ctxSetMyOfferTransactions,
    ctxAddress,
    ctxUsdt,
    ctxFtkx,
    ctxUsdtAllowance,
    ctxFtkxAllowance,
    ctxSetError,
    ctxError,
    ctxSetSuccess,
    ctxSuccess,
    ctxExtraPages,
    ctxSetExtraPages
  } = useContext(UserContext);

  let isLoading = false;
  let newToast = "";
  let myTxList = ctxMyOfferTransactions;
  const [isEditing, setIsEditing] = useState(null as Offer | null);
  const [lastToast, setLastToast] = useState(null as string | number | null);
  const [errorHasBeenShown, setErrorHasBeenShown] = useState(false);
  const [ftkOffer, setFTKOffer] = useState("");
  const [mkfRequired, setMkfRequired] = useState("");
  let {
    writeContract,
    data: hash,
    error,
    isSuccess,
    isError,
    isPending,
  } = useWriteContract({config});

  if (ctxError === "Required Token not found" && !errorHasBeenShown) {
    newToast = "Required Token not found";
    isError = true;
  }
  if (isError){
    console.log(error);
  }

  const {isLoading: isConfirming, isSuccess: isConfirmed} =
    useWaitForTransactionReceipt({
      hash,
    });

  if (isPending || isConfirming) {
    isLoading = true;
  }
  if (isConfirmed || isError) {
    isLoading = false;
  }
  if (!isLoading && isError && error !== null && newToast === "") {
    if ((error.message.match("User rejected the request") || error.message.match("User rejected the request")) && ctxError == "Request Rejected") {

    } else if ((error.message.match("User rejected the request") || error.message.match("User rejected the request"))  && ctxError !== "Request Rejected" && newToast === "") {
      if (ctxError !== "Request Rejected"){
        newToast = "Request Rejected";
      }
    } else if (ctxError === "Not enough allowance" && (error.message.match("0xfb8f41b2") || error.message.match("0xe450d38c"))) {

    } else if (error.message.match("0xfb8f41b2") && newToast === "") {
      if (ctxError !== "Not enough allowance"){
        newToast = "Not enough allowance";
      }
    } else if (ctxError === "Cannot Exceed 4 Active Offers" && (error.message.match("Please use the updateOffer"))) {

    } else if (error.message.match("Please use the updateOffer") && newToast === "") {
      if (ctxError !== "Cannot Exceed 4 Active Offers"){
        newToast = "Cannot Exceed 4 Active Offers";
      }
    } else if (ctxError === "Connector not connected") {

    } else if (error.message.match("Connector not connected") && newToast === ""){
      if (ctxError !== "Connector not connected"){
        newToast = "Connector not connected";
      }
    }else if (ctxError === "Unknown Error") {

    } else if (ctxError !== "Unknown Error" && newToast === ""){
      if (ctxError !== "Unknown Error"){
        newToast = "Unknown Error";
      }
    }
  }

  if (isSuccess && isConfirmed && !isLoading) {
    isLoading = false;
    switch (myTxList[0].type) {
      case TransactionType.ApproveOffer:
        writeContract({
          ...traderContract,
          functionName: "createNewOffer",
          args: [
            BigInt(myTxList[0].ftkxCoin.split('.')[0] + (myTxList[0].ftkxCoin.split('.')[1]?.padEnd(18, "0") || "".padEnd(18, "0"))),
            tokenToUint256(myTxList[0].usdtCoin.concat("0000"), 6),
          ],
        });

        ctxSetMyOfferTransactions(
          [
            {
              confirmedAt: Date.now(),
              type: Number(TransactionType.Offer),
              ftkxCoin: myTxList[0].ftkxCoin,
              usdtCoin: myTxList[0].usdtCoin,
              status: Number(TxStatus.Waiting),
              seller: myTxList[0].seller,
              offerId: myTxList[0].offerId,
            },
          ].concat(
            myTxList.map((t) => {
              if (t.confirmedAt === myTxList[0].confirmedAt) {
                t.status = TxStatus.Confirmed;
              }
              return t;
            })
          )
        );
        if (!isError && newToast === ""){
          isLoading = true;
          newToast = "Offer approved successfully";
        }

        break;
      case TransactionType.Offer:
        if (lastToast !== "Offer created successfully!" && newToast === ""){
          newToast = "Offer created successfully";
        }

        if (myTxList[0].status === TxStatus.Waiting) {
          ctxSetMyOfferTransactions(
            myTxList.map((t) => {
              if (t.confirmedAt === myTxList[0].confirmedAt) {
                t.status = TxStatus.Confirmed;
              }
              return t;
            })
          );
          refetchAll();
        }
        break;
      case TransactionType.Buy:
        if (lastToast !== "Offer bought successfully!" && newToast === ""){
          newToast = "Offer bought successfully";
        }
        if (myTxList[0].status === TxStatus.Waiting) {
          ctxSetMyOfferTransactions(
            myTxList.map((t) => {
              if (t.confirmedAt === myTxList[0].confirmedAt) {
                t.status = TxStatus.Confirmed;
              }
              return t;
            })
          );
          refetchAll();
        }

        break;
      case TransactionType.Delete:
        if (myTxList[0].status === TxStatus.Waiting) {
          ctxSetMyOfferTransactions(
            myTxList.map((t) => {
              if (t.confirmedAt === myTxList[0].confirmedAt) {
                t.status = TxStatus.Confirmed;
              }
              return t;
            })
          );
          refetchAll();
        }
        break;
      case TransactionType.ApproveBuy:
        ctxSetMyOfferTransactions(
          [
            {
              confirmedAt: Date.now(),
              type: Number(TransactionType.Buy),
              ftkxCoin: myTxList[0].ftkxCoin,
              usdtCoin: myTxList[0].usdtCoin,
              status: Number(TxStatus.Waiting),
              seller: myTxList[0].seller,
              offerId: myTxList[0].offerId,
            },
          ].concat(
            myTxList.map((t) => {
              if (t.confirmedAt === myTxList[0].confirmedAt) {
                t.status = TxStatus.Confirmed;
              }
              return t;
            })
          )
        );

        writeContract({
          ...traderContract,
          functionName: "acceptOffer",
          args: [myTxList[0].seller, myTxList[0].offerId],
        });
        break;
      case TransactionType.ApproveUpdate:
        ctxSetMyOfferTransactions(
          [
            {
              confirmedAt: Date.now(),
              type: Number(TransactionType.Buy),
              ftkxCoin: myTxList[0].ftkxCoin,
              usdtCoin: myTxList[0].usdtCoin,
              status: Number(TxStatus.Waiting),
              seller: myTxList[0].seller,
              offerId: myTxList[0].offerId,
            },
          ].concat(
            myTxList.map((t) => {
              if (t.confirmedAt === myTxList[0].confirmedAt) {
                t.status = TxStatus.Confirmed;
              }
              return t;
            })
          )
        );
        writeContract({
          ...traderContract,
          functionName: "updateOffer",
          args: [myTxList[0].seller, myTxList[0].offerId],
        });
        if ( newToast === ""){
          newToast = "Offer updated successfully";
        }
        break;
      default:
    }
  }

  const onBuyOffer = (
    address: Address,
    offerId: number,
    amount: string,
    offeredAmount: string
  ) => {
    var bigIntUsdt = BigInt(amount.split("").filter(d => ("0123456789").includes(d)).join("").normalize().padEnd(19,"0"));
    if (ctxUsdtAllowance >= (bigIntUsdt as BigInt)) {
      writeContract({
        ...traderContract,
        functionName: "acceptOffer",
        args: [address, offerId],
      });

      ctxSetMyOfferTransactions(
        [
          {
            confirmedAt: Date.now(),
            type: Number(TransactionType.Buy),
            ftkxCoin: offeredAmount,
            usdtCoin: amount,
            status: Number(TxStatus.Waiting),
            seller: address,
            offerId: offerId,
          },
        ].concat(myTxList)
        )
    }else {
      writeContract({
        ...newCoinContract,
        functionName: "approve",
        args: [traderContract.address, uint256MaxValue],
      });

      ctxSetMyOfferTransactions(
        [
          {
            confirmedAt: Date.now(),
            type: Number(TransactionType.ApproveBuy),
            ftkxCoin: offeredAmount,
            usdtCoin: amount,
            status: Number(TxStatus.Waiting),
            seller: address,
            offerId: offerId,
          },
        ].concat(myTxList))
    }


  };

  const onDeleteOffer = (
    address: Address,
    offerId: number,
    amount: string,
    offeredAmount: string
  ) => {
    ctxSetMyOfferTransactions(
      [
        {
          confirmedAt: Date.now(),
          type: Number(TransactionType.Delete),
          ftkxCoin: offeredAmount,
          usdtCoin: amount,
          seller: address,
          offerId: offerId,
          status: Number(TxStatus.Waiting),
        },
      ].concat(myTxList)
    );

    writeContract({
      ...traderContract,
      functionName: "cancelOffer",
      args: [offerId],
    });
  };

  const onMakeOffer = (ftkx: string, usdt: string) => {
    if (Number(ctxTokenDate) <= 0 && ctxError !== "Required Token not found"){
      ctxSetError("Required Token not found");
      return
    }

    var bigIntFtkx = BigInt(ftkx.split('.')[0] + (ftkx.split('.')[1]?.padEnd(18, "0") || "".padEnd(18, "0")));
    if (ctxFtkxAllowance >= (bigIntFtkx as BigInt)) {
      writeContract({
        ...traderContract,
        functionName: "createNewOffer",
        args: [
          bigIntFtkx,
          tokenToUint256(usdt.concat("0000"), 6),
        ],
      });

      ctxSetMyOfferTransactions(
        [
          {
            confirmedAt: Date.now(),
            type: Number(TransactionType.Offer),
            ftkxCoin: ftkx,
            usdtCoin: usdt,
            status: Number(TxStatus.Waiting),
            seller: ctxAddress,
            offerId: 0,
          },
        ].concat(
          myTxList
        ))
    }else {
      writeContract({
        ...oldCoinContract,
        functionName: "approve",
        args: [
          traderContract.address,
          uint256MaxValue]
      });

      ctxSetMyOfferTransactions(
        [
          {
            confirmedAt: Date.now(),
            type: Number(TransactionType.ApproveOffer),
            ftkxCoin: ftkx,
            usdtCoin: usdt,
            status: Number(TxStatus.Waiting),
            seller: ctxAddress,
            offerId: 0,
          },
        ].concat(myTxList)
      );
    }
  };

  const formatToUSD = (value: string) => {
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ""));
    return isNaN(numericValue)
      ? ""
      : numericValue.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
  };

  var id = lastToast;
  if (newToast.length > 0) {
    switch(newToast){
      case "Request Rejected":
          if (ctxError !== newToast && lastToast !== "Request Rejected") {
            if (id !== null ) {
              toast.clearWaitingQueue();
            }
            id = toast.error(newToast, {toastId: "Request Rejected"});
            setLastToast(id);
            ctxSetError("Request Rejected");
          }
        break;
      case "Not enough allowance":
          if (ctxError !== newToast && lastToast !== "Not enough allowance") {
            if (id !== null ) {
              toast.clearWaitingQueue();
            }
            id = toast.info(newToast, {toastId: "Not enough allowance"});
            setLastToast(id);
            ctxSetError("Not enough allowance");
          }
        break;
        case "Cannot Exceed 4 Active Offers":
            if (ctxError !== newToast && lastToast !== "Cannot Exceed 4 Active Offers") {
              if (id !== null ) {
                toast.clearWaitingQueue();
              }
              id = toast.info(newToast, {toastId: "Cannot Exceed 4 Active Offers"});
              setLastToast(id);
              ctxSetError("Cannot Exceed 4 Active Offers");
            }
          break;
      case "Unknown Error":
          if (ctxError !== newToast && lastToast !== "Unknown Error") {
            if (id !== null ) {
              toast.clearWaitingQueue();
            }
            id = toast.error(newToast, {toastId: "Unknown Error"});
            setLastToast(id);
            ctxSetError("Unknown Error");
          }
        break;
        case "Connector not connected":
            if (ctxError !== newToast && lastToast !== "Connector not connected") {
              if (id !== null ) {
                toast.clearWaitingQueue();
              }
              id = toast.error(newToast, {toastId: "Connector not connected"});
              setLastToast(id);
              ctxSetError("Connector not connected");
            }
          break;
      case "Offer bought successfully":
          if (ctxSuccess !== newToast && lastToast !== "Offer bought successfully") {
            if (id !== null ) {
              toast.clearWaitingQueue();
            }
            id = toast.success(newToast, {toastId: "Offer bought successfully"});
            setLastToast(id);
            ctxSetSuccess("Offer bought successfully");
          }
        break;
      case "Offer deleted successfully":
          if (ctxSuccess !== newToast && lastToast !== "Offer deleted successfully") {
            if (id !== null ) {
              toast.clearWaitingQueue();
            }
            id = toast.success(newToast, {toastId: "Offer deleted successfully"});
            setLastToast(id);
            ctxSetSuccess("Offer deleted successfully");
          }
        break;
      case "Offer approved successfully":
          if (ctxSuccess !== newToast && lastToast !== "Offer approved successfully") {
            if (id !== null ) {
              toast.clearWaitingQueue();
            }
            id = toast.success(newToast, {toastId: "Offer approved successfully"});
            setLastToast(id);
            ctxSetSuccess("Offer approved successfully");
          }
        break;
      case "Offer updated successfully":
          if (ctxSuccess !== newToast && lastToast !== "Offer updated successfully") {
            if (id !== null ) {
              toast.clearWaitingQueue();
            }
            id = toast.success(newToast, {toastId: "Offer updated successfully"});
            setLastToast(id);
            ctxSetSuccess("Offer updated successfully");
          }
        break;
      case "Required Token not found":
        if (ctxError !== newToast && lastToast !== "Required Token not found") {
          if (id !== null ) {
            toast.clearWaitingQueue();
          }
          id = toast.error(newToast, {toastId: "Required Token not found"});
          setLastToast(id);
          ctxSetError("Required Token not found");
        }
      break;
        default:
    }
  }
  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const bottom = e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight;
    if (bottom) {
      ctxSetExtraPages(ctxExtraPages + 1);
    }
  }
  var formUnitPrice = Number(mkfRequired.replaceAll('$','').replaceAll(',','') ?? 0)/Number(ftkOffer ?? 1);
  var lastFormUnitPrice = isFinite(formUnitPrice) ? formUnitPrice.toFixed(6) : "Invalid"
  return (
    <>
      <div className="container">
        <div className="marginsx">
          <div className="abrs">
            <div className="order">
              <span className={"spanBg"}></span>
              <div className="titulo">
                <h1>Open Orders</h1>
                <span className="syncTimeStamp">
                  Last Synced at:{" "}
                  {ctxLastUpdate.toLocaleString(DateTime.DATETIME_FULL)}
                </span>
              </div>
              <div className="orderInt">
                <div className="right">
                  <div className="headeerCabeGreen">Buy Order</div>
                  <div className="rightInt">
                    <div className="buyHeadeers">
                      <div className="col">Action</div>
                      <div className="col">FTKX</div>
                      <div className="col">USDT</div>
                      <div className="col">Unit Price</div>
                      <div className="col">Address</div>
                    </div>
                    <div className="body" onScroll={handleScroll}>
                      {ctxOffers.concat(ctxOffers2).concat(ctxOffers3).concat(ctxOffersExtra.flat()).map((offer: Offer, index: number) => {
                        const addr = offer.seller.toString();
                        return (
                          <div key={`${index}`} className="orderRow">
                            <div className="col mediumText">
                              {offer.seller === ctxAddress ? (
                                <button
                                  disabled={true}
                                  className="btnWarningInput"
                                >
                                  {" "}
                                  Yours
                                </button>
                              ) : (
                                <button
                                  className={
                                    "btnInputs handleMsg cursorPointer"
                                  }
                                  onClick={() => {
                                    onBuyOffer(
                                      offer.seller,
                                      offer.offerId,
                                      offer.usdtCoin,
                                      offer.ftkxCoin
                                    );
                                  }}
                                >
                                  Buy
                                </button>
                              )}
                            </div>
                            <div className="col mediumText">
                              {offer.ftkxCoin.substring(0, (offer.ftkxCoin.indexOf('.')+3))}
                            </div>
                            <div className="col mediumText">
                              ${FormatNumber(Number(offer.usdtCoin))}
                            </div>
                            <div className="col mediumText">
                              ${(Number(offer.usdtCoin)/Number(offer.ftkxCoin)).toString().substring(0,(Number(offer.usdtCoin)/Number(offer.ftkxCoin)).toString().indexOf(".")+7)}
                            </div>
                            <div className="col mediumText">
                              {addr.substring(0, 3) +
                                "...." +
                                addr.substring(addr.length - 3)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="buyFounder">
              <span className={"spanBg"}></span>
              <div className={"titulo"}>
                <h1>Sales offer for FTKX</h1>
              </div>
              <div className={"blocoInputs"}>
                <div className={"fullWidth"}>
                  <p className={"pInput"}>FTKX to offer</p>
                  <span className="inputRelativ">
                    <input
                      type="text"
                      className="inputs"
                      value={ftkOffer}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e?.target?.value;
                        if (/^\d*\.?\d*$/.test(value)) {
                          if (value.length >= 50)
                            return;
                          if (value.split(".")[1] && value.split(".")[1].length > 2)
                            return;
                          setFTKOffer(value);
                        }
                      }}
                      autoFocus={true}
                      placeholder="0.00"
                      inputMode="numeric"
                      style={{
                        border: `${
                          Number(ftkOffer) > Number(ctxFtkx)
                            ? "1px solid red"
                            : ""
                        }`,
                      }}
                    />
                    <p className="fixInput">FKTX</p>
                  </span>
                  <p className={"pInput"}>Offer Price (USDT)</p>
                  <span className="inputRelativ">
                    <input
                      type="text"
                      className="inputs"
                      value={mkfRequired}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value)) {
                          if (value.length >= 50)
                            return;
                          if (value.split(".")[1] && value.split(".")[1].length > 2)
                            return;
                          setMkfRequired(value);
                        }
                      }}
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        const value = e.target.value;
                        setMkfRequired(formatToUSD(value));
                      }}
                      placeholder="0.00"
                      inputMode="numeric"
                    />
                    <p className="fixInput">USDT</p>
                  </span>
                    <p className="pInput"style={{
                              color: `white`,
                              display: `${
                                isFinite(formUnitPrice)?
                                "inline"
                                :
                                "none"
                              }`
                            }}>Unit Price: ${lastFormUnitPrice}</p>

                  <div className="mkfButtons mb-15 mt-15">
                    <button
                      className={"sellBtnInputsS fullWidth cursorPointer p-2"}
                      onClick={() => {
                        onMakeOffer(ftkOffer, mkfRequired);
                        setFTKOffer("");
                        setMkfRequired("");
                      }}
                      disabled={
                        Number(ftkOffer) > Number(ctxFtkx) ||
                        Number(mkfRequired) <= 0 ||
                        Number(ftkOffer) <= 0
                      }
                    >
                      Make Offer
                    </button>
                  </div>
                  {/* {!!isEditing ? (
                    <div className="mkfButtons mb-15 mt-15">
                      <button
                        className={"sellBtnInputsS halfWidth cursorPointer"}
                        onClick={() => {
                          ctxSetMyOfferTransactions(
                            [
                              {
                                confirmedAt: Date.now(),
                                type: Number(TransactionType.Delete),
                                ftkxCoin: ftkOffer,
                                usdtCoin: mkfRequired,
                                status: Number(TxStatus.Waiting),
                                seller: isEditing.seller,
                                offerId: isEditing.offerId,
                              },
                            ].concat(myTxList)
                          );
                          onDeleteOffer(
                            isEditing.seller,
                            isEditing.offerId,
                            ftkOffer,
                            mkfRequired
                          );
                          toast.success("Offer created successfully!");
                          setFTKOffer("");
                          setMkfRequired("");
                        }}
                      >
                        Delete Offer
                      </button>
                    </div>
                  ) : Number(ftkOffer) > 0 && Number(mkfRequired) > 0 ? (
                    <div className="mkfButtons mb-15 mt-15">
                      <button
                        className={"sellBtnInputsS halfWidth cursorPointer p-2"}
                        onClick={() => {
                          ctxSetMyOfferTransactions(
                            [
                              {
                                confirmedAt: Date.now(),
                                type: Number(TransactionType.ApproveOffer),
                                ftkxCoin: ftkOffer,
                                usdtCoin: mkfRequired,
                                status: Number(TxStatus.Waiting),
                                seller: ctxAddress,
                                offerId: 0,
                              },
                            ].concat(myTxList)
                          );
                          toast.success("Offer created successfully!");
                          onMakeOffer(ftkOffer, mkfRequired);
                          setFTKOffer("");
                          setMkfRequired("");
                        }}
                      >
                        Make Offer
                      </button>
                    </div>
                  ) : (
                    <div className="mkfButtons  mb-15 mt-15">
                      <button className={"sellBtnInputsS halfWidth p-2"}>
                        Make Offer
                      </button>
                    </div>
                  )} */}
                  <div className={"blocoCoin mt-15"}>
                    <div className="col d-flex justify-content-center align-items-center gap-1">
                      <div className=" d-flex justify-content-center">
                        <img className={"coinX"} alt="coin" src={imgT} />
                      </div>
                      <div className=" d-flex flex-column coinColumn">
                        <p className="total">Total in Wallet</p>
                        <div className={"coinFtk"}>
                          {ctxUsdt ? (
                            <span>{FormatNumber(Number(ctxUsdt))}</span>
                          ) : (
                            <Spinner size="sm" />
                          )}
                          <p>USDT</p>
                        </div>
                      </div>
                    </div>
                    <div className="col d-flex justify-content-center align-items-center gap-1">
                      <div className=" d-flex justify-content-center">
                        <img className={"coinX"} alt="coin" src={imgX} />
                      </div>
                      <div className=" d-flex flex-column coinColumn">
                        <p className="total">Total in Wallet</p>
                        <div className={"coinFtk"}>
                          {ctxFtkx ? (
                            <span>{ctxFtkx}</span>
                          ) : (
                            <Spinner size="sm" />
                          )}
                          <p>FTKX</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={"transacciones"}>
            <div className={"titulo"}>
              <h1>My Offers</h1>
            </div>
            <span className={"spanBg"}></span>
            <div className={"table"}>
              <div className={"txHeader"}>
                <div className="col">Action</div>
                <div className="col">FTKX</div>
                <div className="col">USDT</div>
                <div className="col">Unit Price</div>
              </div>
              <div className="bodyOver">
                {ctxMyOffers.map((offer, index) => (
                  <>
                    <div key={`${index}`} className="orderRow">
                      <div className="col mediumText">
                        {offer.isActive ? (
                          <button
                            className={"sellBtnInputsS handleMsg cursorPointer"}
                            onClick={() => {
                              onDeleteOffer(
                                offer.seller,
                                offer.offerId,
                                offer.usdtCoin,
                                offer.ftkxCoin
                              );
                            }}
                          >
                            Delete Offer
                          </button>
                        ) : (
                          <button
                            className="btnInputs handleMsg cursorPointer"
                            onClick={() => {
                              setIsEditing({
                                seller: offer.seller,
                                offerId: offer.offerId,
                                ftkxCoin: offer.ftkxCoin,
                                usdtCoin: offer.usdtCoin,
                              } as Offer);
                              setFTKOffer(offer.ftkxCoin);
                              setMkfRequired(offer.usdtCoin);
                            }}
                            disabled={true}
                          >
                            None
                          </button>
                        )}
                      </div>
                      <div className="col mediumText">
                        {!offer.isActive
                          ? "-"
                          : offer.ftkxCoin}
                      </div>
                      <div className="col mediumText">
                        {!offer.isActive 
                          ? "-"
                          : "$" + offer.usdtCoin}
                      </div>
                      <div className="col mediumText">
                        {!offer.isActive 
                          ? "-"
                          :
                          "$" + (Number(offer.usdtCoin)/Number(offer.ftkxCoin)).toString().substring(0,(Number(offer.usdtCoin)/Number(offer.ftkxCoin)).toString().indexOf(".")+3)
                          }
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? <LoadingModal /> : <></>}
      {!isLoading && isSuccess && ctxSuccess? <SuccessModal successMessage={ctxSuccess} /> : <></>}
      {!isLoading && !isSuccess && ctxError ? <ErrorModal errorMessage={ctxError} /> : <></>}
    </>
  );
};

export default OrderBook;
