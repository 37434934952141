import styles from './Loading.module.css'

import logoDentro from '../../containers/logoDentroNew.png';
import logoFora from '../../containers/logoForaNew.png';
import bg from '../../containers/bgNew.png';

export const Loading = () => {
  return (
    <div
      style={
        {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${bg})`,
          backgroundColor: "#0E0E0E",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "90vh",
          zIndex: 9999,
          color: '#fff'
        }
      }
    >
      <div className={styles.effect}></div>
      <span className={styles.logoGroup}>
        <img src={logoDentro} alt="logo" />
        <img src={logoFora} alt="logo" />
      </span>
    </div>
  );
};
